<template>
  <main class="modal-card">
    <icn-close
      class="modal-card__close"
      @click="$emit('close')"
    />
    <slot />
  </main>
</template>

<script>
import { IcnClose } from '@revelotech/everest'

export default {
  name: 'ModalCard',
  components: {
    IcnClose
  }
}
</script>

<style lang="scss">
.modal-card {
  background: $white;
  flex-grow: 1;
  padding: $base*6;
  position: relative;
  width: 100%;

  &__close {
    cursor: pointer;
    position: fixed;
    right: $base*4;
    top: $base*4;
  }

  @include breakpoint(lg) {
    flex-grow: 0;
    padding: $base*12 $base*12 $base*6;

    &__close {
      position: absolute;
    }
  }
}
</style>
