<template>
  <section class="candidate-form">
    <app-grid class="candidate-form__row">
      <app-grid-col :md="12">
        <app-text-input
          v-model="name"
          v-validate="'required'"
          name="name"
          :label="$t(`${langPath}.nameField`)"
          :data-vv-as="$t(`${langPath}.nameField`)"
        />
      </app-grid-col>
    </app-grid>
    <app-grid class="candidate-form__row">
      <app-grid-col :md="6">
        <app-text-input
          v-model="phone"
          v-mask="phoneMask"
          name="phone"
          :label="$t(`${langPath}.phoneField`)"
          :data-vv-as="$t(`${langPath}.phoneField`)"
          placeholder="(00) 0000-0000"
        />
      </app-grid-col>
      <app-grid-col :md="6">
        <app-text-input
          v-model="email"
          v-validate="'required'"
          name="email"
          :label="$t(`${langPath}.emailField`)"
          :data-vv-as="$t(`${langPath}.emailField`)"
        />
      </app-grid-col>
    </app-grid>
    <app-grid class="candidate-form__row">
      <app-grid-col :md="12">
        <app-file-uploader
          v-model="resume"
          data-test="file-uploader"
          name="resume"
          button-variant="primary"
          button-title="Procurar"
          :accept="mimetypes"
          :label="$t(`${langPath}.resumeField`)"
          :data-vv-as="$t(`${langPath}.resumeField`)"
          :placeholder="resumeName || $t(`${langPath}.resumePlaceholder`)"
        />
      </app-grid-col>
    </app-grid>
    <app-grid class="candidate-form__row">
      <app-grid-col :md="6">
        <app-text-input
          v-model="linkedinUrl"
          name="linkedinUrl"
          placeholder="https://"
          :label="$t(`${langPath}.linkedinField`)"
          :data-vv-as="$t(`${langPath}.linkedinField`)"
        />
      </app-grid-col>
      <app-grid-col :md="6">
        <app-text-input
          v-model="portfolioUrl"
          name="portfolioUrl"
          placeholder="https://"
          :label="$t(`${langPath}.siteField`)"
          :data-vv-as="$t(`${langPath}.siteField`)"
        />
      </app-grid-col>
    </app-grid>
    <app-grid class="candidate-form__row">
      <app-grid-col :md="12">
        <p class="candidate-form__label">{{ $t(`${langPath}.sourcePlaceholder`) }}</p>
        <app-searchable-select
          v-model="sourceInfo"
          v-validate="'required'"
          data-test="source-select"
          name="sourceInfo"
          :add-prepend-text="$t(`${langPath}.addPrepend`)"
          :items="sourcesList"
          :select-placeholder="$t(`${langPath}.sourcePlaceholder`)"
          :data-vv-as="$t(`${langPath}.sourcePlaceholder`)"
          can-add
          @input-add="addHandler"
        />
      </app-grid-col>
    </app-grid>
    <footer class="candidate-form__button">
      <ev-button
        data-test="candidate-form-submit"
        :loading="isSending"
        @click="handleSubmit"
      >
        {{ submitButtonLabel }}
      </ev-button>
    </footer>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'CandidateForm',
  components: {
    EvButton
  },
  props: {
    submitButtonLabel: {
      required: true,
      type: String
    },
    isSending: {
      required: false,
      default: false,
      type: Boolean
    },
    candidate: {
      required: false,
      default: () => ({}),
      type: Object
    }
  },
  data () {
    const langPath = __langpath
    return {
      langPath,
      name: '',
      email: '',
      phone: '',
      resume: undefined,
      resumeUrl: '',
      portfolioUrl: '',
      linkedinUrl: '',
      sourceInfo: '',
      sourcesList: [{
        items: [
          this.$t(`${langPath}.recommendation`),
          'GitHub',
          'LinkedIn'
        ].map(value => ({ value, label: value }))
      }]
    }
  },

  computed: {
    resumeName () {
      if (!this.candidate.resumeUrl) { return }

      const resume = this.candidate.resumeUrl
      const resumeSplitted = resume.split('/')
      const fileName = resumeSplitted[resumeSplitted.length - 1]

      return decodeURI(fileName)
    },
    mimetypes () {
      return [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ]
    },
    phoneMask () {
      return ['(##) ####-####', '(##) #####-####']
    }
  },

  watch: {
    candidate: {
      immediate: true,
      handler (value) {
        if (!Object.entries(value).length) { return }

        this.name = value.name
        this.email = value.email
        this.phone = value.phone
        this.resumeUrl = value.resumeUrl
        this.portfolioUrl = value.portfolioUrl
        this.linkedinUrl = value.linkedinUrl
        this.sourceInfo = value.sourceInfo

        const sourcesList = this.sourcesList
        if (!value.sourceInfo ||
          sourcesList[0].items
            .filter((item) => item.value === value.sourceInfo).length
        ) return

        this.sourcesList[0].items.push({
          label: value.sourceInfo,
          value: value.sourceInfo
        })
      }
    }
  },

  methods: {
    addHandler (item) {
      if (!item) return
      this.sourcesList[0].items.push({ label: item, value: item })
      this.sourceInfo = item
    },
    async handleSubmit () {
      if (!await this.$validator.validate()) { return }

      this.$emit('submit', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        resume: this.resume,
        portfolioUrl: this.portfolioUrl,
        linkedinUrl: this.linkedinUrl,
        sourceInfo: this.sourceInfo
      })
    }
  }
}
</script>

<style lang="scss">
.candidate-form {
  &__row > section {
    margin: $base*6 0 0;
  }

  &__label {
    @extend %body-text2;

    margin-bottom: $base*2;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: $base*6;
  }
}
</style>
